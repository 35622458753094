// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'manager',
  initialState: {
    managerData: [],
    managerName:"Manager",
    resetManager:[]
  },
  reducers: {
    managerData: (state, action) => {
      state.managerData = action.payload
    },
    managerName: (state, action) => {
      state.managerName = action.payload
    },
    resetManager: state => {
      state.managerData = []
      
    }
    
}
})

export const { managerData, managerName, resetManager } = userSlice.actions

export default userSlice.reducer