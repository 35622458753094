// import { Mail, Home, User } from "react-feather"

// export default [
//   {
//     id: "home",
//     title: "Dashboard",
//     icon: <Home size={20} />,
//     navLink: "/home"
//   },
//   {
//     id: "Users",
//     title: "Users",
//     icon: <User size={20} />,
//     navLink: "/users"
//   },
//   {
//     id: 'Enterprise',
//     title: 'Enterprise',
//     icon: <User size={20} />,
//     navLink: '/enterprise',
//   },
//   {
//     id: 'Department',
//     title: 'Department',
//     icon: <User size={20} />,
//     navLink: '/department',
//   },
//   {
//     id: 'enterpriseManager',
//     title: 'Entp. Manager',
//     icon: <User size={20} />,
//     navLink: '/enterprise-manager',
//   },
//   {
//     id: 'enterpriseUser',
//     title: 'Entp. User',
//     icon: <User size={20} />,
//     navLink: '/enterprise-user',
//   },
//   {
//     id: 'individualUser',
//     title: 'Indv. User',
//     icon: <User size={20} />,
//     navLink: '/individual-user',
//   },
//   {
//     id: 'SMEAdmin',
//     title: 'SME Admin',
//     icon: <User size={20} />,
//     navLink: '/sme-admin',
//   },
//   // {
//   //   id: 'Package',
//   //   title: 'Package',
//   //   icon: <User size={20} />,
//   //   navLink: '/package-details',
//   // },
//   // {
//   //   id: 'Topicsandkeys',
//   //   title: 'Topics & Keys',
//   //   icon: <User size={20} />,
//   //   navLink: '/topics-keywords',
//   // },
//   {
//     id: 'reviews',
//     title: 'Reviews',
//     icon: <User size={20} />,
//     navLink: '/reviews',
//   },

//   // {
//   //   id: 'SME',
//   //   title: 'SME',
//   //   icon: <Home />,
//   //   children: [
//   //     {
//   //       id: 'smeList',
//   //       title: 'List',
//   //       icon: <Home />,
//   //       navLink: '/sme'
//   //     },
//   //     // {
//   //     //   id: 'eCommerceDash',
//   //     //   title: 'eCommerce',
//   //     //   icon: <ShoppingCart />,
//   //     //   navLink: '/dashboard/ecommerce'
//   //     // }
//   //   ]
//   // }
// ]
// import { FaRegBuilding } from 'react-icons/fa';
// import { IoIosReturnLeft } from 'react-icons/io';
import React from "react";

import {useSelector} from 'react-redux'

import {
  Mail,
  Home,
  Map,
  Book,
  BarChart2,
  Circle,
  Sliders,
  Plus,
  PieChart,
  Disc,
  Tag,
  User,
  Users
} from "react-feather"
import { handleNavigation } from "../../redux/navigation";
import { store } from "../../redux/store";
import { HiOutlineTicket } from "react-icons/hi";
import { MdOutlineTopic } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { IoLanguage } from "react-icons/io5";
import { LuMessagesSquare } from "react-icons/lu";
import { FaMoneyBill } from "react-icons/fa";
import { SlBadge } from "react-icons/sl";
import { LuBuilding2 } from "react-icons/lu";
import { GrUserManager } from "react-icons/gr";
import { TbTemplate } from "react-icons/tb";
import { IoNotificationsOutline } from "react-icons/io5";

const defaultNavigation = [
  {
      id:'Home',
      title:'Home',
      icon:<Home size={12} />,
      navLink:'/home'
     
  }
]

// Generate navigation based on the provided data
// const generateNavigation = (data) => {
//   const dataArray = Object.values(data);
//   const mappedArray = dataArray.map((item, index) => ({
//     id: item.page_name,
//     title: item.page_name,
//     icon: React.createElement(getIconComponent(item.icon), { size: 20 }),
//     navLink: item.route_path,
//   }));
//   return mappedArray;
// };

const iconList={

  "home":<Home/>,
  "enterprises":<Home/>,
  "user_management":<Users/>,
  "indv_management":<User />,
  "sub_management":<HiOutlineTicket />,
  "title_sme":<MdOutlineTopic />,
  "setting":<CiSettings />,
  "languages":<IoLanguage />,
  "chatboard":<LuMessagesSquare />,
  "charity":<FaMoneyBill />,
  "reward_management":<SlBadge />,
  "entp_management":<LuBuilding2 />,
  "sme_management":<GrUserManager />,
  "schedule_templates":<TbTemplate />,
  "notifications":<IoNotificationsOutline />,


}

async function filterNavigation(data) {
  // console.log('navigation data: ', data)
  store.dispatch(handleNavigation(data))
  let nav=[]
  let allNav=[]

  for(let i=0;i<data?.length;i++){

      let currentNode=data[i]

     
      let obj={}

      obj.id=currentNode?.id
      obj.title=currentNode?.page_name
      obj.navLink=currentNode?.route
      obj.icon=iconList[currentNode.page_key] || <Disc/>

      obj.navLink!='' && allNav.push(obj.navLink)

      if(currentNode?.children?.length>0){

          const childArr=currentNode.children

          let children=childArr.map(group=>{

              let childObj={}

              childObj.id=group.id
              childObj.title=group.page_name
              childObj.navLink=group.route
              childObj.icon=iconList[group.page_name] || <Disc/>
              
              childObj.navLink!='' && allNav.push(childObj.navLink)

              if(group?.children?.length>0){

                  let gChildArr=group.children

                  let gChildren=gChildArr.map(groupSection=>{

                      let gChildObj={}
      
                      gChildObj.id=groupSection.id
                      gChildObj.title=groupSection.page_name
                      gChildObj.navLink=groupSection.route
                      gChildObj.icon=iconList[groupSection.page_name] || <Circle/>

                      gChildObj.navLink!='' && allNav.push(gChildObj.navLink)

                      return gChildObj
                  })
                  
                  childObj.children=gChildren

              }

              return childObj
          })
          obj.children=children

      }
      nav.push(obj)
  }
  allNav.unshift('/home')
  localStorage.setItem("navLinks",JSON.stringify(allNav))

  return nav

}
export const getNavigation = async () => {
  const navigationData = localStorage.getItem('navigation')

  if (
    navigationData != 'undefined' &&
    navigationData != null &&
    navigationData.length != 0
  ) {
    // console.log("HEHE!")
    const filteredNavigation = await filterNavigation(JSON.parse(navigationData));
    return filteredNavigation;
  }
  // console.log("HEHE2")
  return defaultNavigation;
};
// export default generateNavigation;