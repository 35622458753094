// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'enterprise',
  initialState: {
    enterpriseName:"Enterprise",
    enterpriseData: [],
    subscriptionData: [],
    categoriesData: [],
    depByEntpID: [],
    depByIDName:"",
    managerByEntpID:[],
    managerByEntpName: "",
    usersDataByID:[],
    usersByIDName:"",

    enterprisePassword:'',

    selectedUsersOfManager:[],

    resetEnterprise:[]

  },
  reducers: {
    enterpriseName: (state, action) => {
      state.enterpriseName = action.payload
    },
    enterpriseData: (state, action) => {
      state.enterpriseData = action.payload
    },
    categoriesData: (state, action) => {
      state.categoriesData = action.payload
    },
    subscriptionData: (state, action) => {
      state.subscriptionData = action.payload
    },
    depByEntpID: (state, action) => {
      state.depByEntpID = action.payload
    },
    depByIDName: (state, action) => {
      state.depByIDName = action.payload
    },
    managerByEntpID: (state, action) => {
      state.managerByEntpID = action.payload
    },
    managerByEntpName: (state, action) => {
      state.managerByEntpName = action.payload
    },
    usersDataByID: (state, action) => {
      state.usersDataByID = action.payload
    },
    usersByIDName: (state, action) => {
      state.usersByIDName = action.payload
    },
    enterprisePassword: (state, action) => {
      state.enterprisePassword = action.payload
    },

    resetEnterprise: state => {
       state.enterpriseName = "Enterprise";
    state.enterpriseData = [];
    state.subscriptionData = [];
    state.categoriesData = [];
    state.depByEntpID = [];
    state.depByIDName = "";
    state.managerByEntpID = [];
    state.managerByEntpName = "";
    state.usersDataByID = [];
    state.usersByIDName = "";
    state.enterprisePassword = "";
    }

    
}
})

export const { enterpriseData, enterpriseName, subscriptionData, 
    categoriesData, depByEntpID, depByIDName, managerByEntpID,managerByEntpName,
     usersDataByID,
    usersByIDName, 

    enterprisePassword, 
    resetEnterprise,
    
  } = userSlice.actions

export default userSlice.reducer