// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'enterpriseUser',
  initialState: {
    enterpriseUserData: [],
    enterpriseUserName:"Enterprise User",

    resetEntpUser:[]
  },
  reducers: {
    enterpriseUserData: (state, action) => {
      state.enterpriseUserData = action.payload
    },
    enterpriseUserName: (state, action) => {
      state.enterpriseUserName = action.payload
    },
    resetEntpUser: state => {
      state.enterpriseUserData = []
     
    }
    
}
})

export const { enterpriseUserData, enterpriseUserName, resetEntpUser} = userSlice.actions

export default userSlice.reducer