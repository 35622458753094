import toast from 'react-hot-toast'


export const statusOptions = [
    { label:"ACTIVE", value:"active" },
    { label:"INACTIVE", value:"inactive" },
    { label:"BANNED", value:"banned" },
    { label:"DELETED", value:"deleted" },
]
export const emailOptions = [
    { email:"talha.hassan@skillsetsonline.com"},
    { email:"shamsa12@gmail.com"},
    
]
export const statusSMEOptions = [
    { label:"ACTIVE", value:"active" },
    { label:"INACTIVE", value:"inactive" },
    { label:"BANNED", value:"banned" },
]

export const tutorialStatusOptions = [
    { label:"Approved", value:"approved" },
    { label:"Rejected", value:"rejected" },
    { label:"Technical Review", value:"waiting_for_technical_review" },
    { label:"Final Review", value:"waiting_for_final_review" },
]
export const bulkTutorialStatusFinalOptions = [
    { label:"Reject", value:"rejected" },
    { label:"Approve", value:"approved" },
]
export const bulkTutorialStatusTechnicalOptions = [
    { label:"Reject", value:"rejected" },
    { label:"Final Review", value:"waiting_for_final_review" },
]
export const catStatusOptions = [
    { label:"ACTIVE", value:"active" },
    { label:"INACTIVE", value:"inactive" },
]
export const pushNotificationOptions = [
    { label:"ACTIVE", value:"1" },
    { label:"INACTIVE", value:"0" },
]
export const subTypeOptions = [
    { label:'Monthly', value:'monthly' },
    { label:'Lifetime', value:'lifetime' },
    { label:'Quarterly', value:'quarterly' },
    { label:'Yearly', value:'yearly' },
]
export const subModelOptions = [
    { label:'Enterprise', value:'enterprise'},
    { label:'Individual', value:"individual" }
]
export const loginTypeOptions = [
    { label:'Admin', value:'admin'},
    { label:'SME', value:'sme' }
]
export const notificationOptions = [
    { label:'ON', value:'on' },
    { label:'OFF', value:'off' }
]
export const userRoleOptions = [
    { label:'Admin', value:'admin' },
    { label:'Super Admin', value:'superadmin' },
    { label:'Sales', value:'sales' },
    { label:'SME Mgt.', value:'sme' },
    // { label:'Reviewer', value:'reviewer' },
    { label:'QA', value:'QA' },
]
export const paymentOptions = [
    { label:'Paid', value:'paid' },
    { label:'Un Paid', value:'unpaid' },
]
export const genderOptions = [
    { label:'Male', value:'male' },
    { label:'Female', value:'female' },
]
export const qualificationOptions = [
    { label:"No formal education", value:"No formal education" },
    { label:"Primary education", value:"Primary education" },
    { label:"Secondary education or high school", value:"Secondary education or high school" },
    { label:"GED", value:"GED" },
    { label:"Vocational qualification", value:"Vocational qualification" },
    { label:"Bachelor's degree", value:"Bachelor's degree" },
    { label:"Master's degree", value:"Master's degree" },
    { label:"Doctorate or higher", value:"Doctorate or higher" },
]
// No formal education
// Primary education
// Secondary education or high school
// GED
// Vocational qualification
// Bachelor's degree
// Master's degree
// Doctorate or higher

export const myToast = (myPromise) => {

    return toast.promise(myPromise, {
        loading: 'Loading, Please Wait',
        // success: 'Data Inserted Successfully',
        success: (data) => `${data.message}`,
        error: (data) => `${data.message}`
    },
    {
        success: {
          duration: 3000 // Adjust the duration the success message is displayed
        }
    }
    )
}

// Image Url
export const getImageUrl = (imageUrl) => {

    // const url = localStorage.getItem('baseURL')
    // const newUrl = url.split('/')
    // newUrl.pop()
    // if(imageUrl=='undefined')
    // {

    // }
    // return newUrl.join('/') + httpGetFile + imageUrl
    // return url + '/' + imageUrl

    if(imageUrl=='' || imageUrl==null ){
        return null
    }else if(imageUrl?.includes('blob')){
      return imageUrl
    }else {
        const url = 'http://34.16.62.73/oe';
        const validExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        
        if (!imageUrl.startsWith('uploads/')) {
            imageUrl = `uploads/${imageUrl}`;
        }
        
        const hasValidExtension = validExtensions.some(ext => imageUrl.toLowerCase().endsWith(`.${ext}`));
        
        // Add '.png' extension if no valid extension found
        if (!hasValidExtension) {
            imageUrl += '.png';
        }
        
        return `${url}/${imageUrl}`;
    }
}
export const toFormatDateBE=(passDate)=>{
    
    const date= new Date(passDate)

    const year= date.getFullYear()
    
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const finalDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    // Example output: 2023/24/05 10:47:33

    return finalDate
}
// export const toFormatDate=(passDate)=>{
    
//     const date= new Date(passDate)

//     const year= date.getFullYear()
//     // const month= date.getMonth()+1
//     // const day= date.getDate()
//     // const hours = date.getHours();
//     // const minutes = date.getMinutes();
    
//     const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     const day = date.getDate().toString().padStart(2, '0');
//     const hours = date.getHours().toString().padStart(2, '0');
//     const minutes = date.getMinutes().toString().padStart(2, '0');

//     // const finalDate= month+'/'+day+'/'+year
// // 05/24/2023 -- OUTPUT

// const finalDate = `${day}/${month}/${year} ${hours}:${minutes}`;
//     // Example output: 05/24/2023 10:47

//     // const date = new Date(passDate);

//     // const year = date.getFullYear();
//     // const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     // const day = date.getDate().toString().padStart(2, '0');
//     // const hours = date.getHours().toString().padStart(2, '0');
//     // const minutes = date.getMinutes().toString().padStart(2, '0');

//     // const finalDate = `${month}/${day}/${year} ${hours}:${minutes}`;
//     // // Example output: 01/15/2024 10:47

//     return finalDate
// }


export const toFormatDate = (passDate) => {
    const date = new Date(passDate);

    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    // Construct the final date string in UTC
    const finalDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    return finalDate;
}



export const toFormatDateWOTime=(passDate)=>{
    
    const date= new Date(passDate)

    const year= date.getFullYear()
    // const month= date.getMonth()+1
    // const day= date.getDate()
    // const hours = date.getHours();
    // const minutes = date.getMinutes();
    
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // const finalDate= month+'/'+day+'/'+year
// 05/24/2023 -- OUTPUT

const finalDate = `${day}/${month}/${year}`;
    // Example output: 05/24/2023 10:47

    // const date = new Date(passDate);

    // const year = date.getFullYear();
    // const month = (date.getMonth() + 1).toString().padStart(2, '0');
    // const day = date.getDate().toString().padStart(2, '0');
    // const hours = date.getHours().toString().padStart(2, '0');
    // const minutes = date.getMinutes().toString().padStart(2, '0');

    // const finalDate = `${month}/${day}/${year} ${hours}:${minutes}`;
    // // Example output: 01/15/2024 10:47

    return finalDate
}

export const todayDate=()=>{
    
    const date= new Date()

    const year= date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

const finalDate = `${year}-${month}-${day}`;

    return finalDate
}




